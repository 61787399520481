//Angular inputs
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { RepositoryService } from '../repository/repository.service';
import { HttpHeaders } from '@angular/common/http';

//Model inputs
import { Newsfeed } from 'app/shared/models/newsfeed.model';
import { Payload } from 'app/shared/models/payload.model';
import { FilterRequest } from 'app/shared/models/filterRequest.model';

@Injectable({
  providedIn: 'root'
})
export class NewsfeedService {

  public newsFeedBannerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public updateNewsfeedBanner$ = this.newsFeedBannerSubject.asObservable();
  accessToken: string = "";

  constructor(
    private repository: RepositoryService,
  ) {  }

  public updateNewsfeedBanner(update: boolean) {
    this.newsFeedBannerSubject.next(update);
  }

  public getBannerNewsfeeds(): Observable<Newsfeed[]> {
    return new Observable((observer) => {
      this.repository.get(environment.apiBaseURL, 'Announcement/GetBannerNewsFeeds')
        .subscribe(data => {
          observer.next(data);
          observer.complete();
        }, (error) => {
          observer.error(error);
        });
    });
  }

  getAllNewsfeedPaged(sortColumn: string, sortDir: string, page: number, pageSize: number, filter: string): Observable<Payload> {
    var filterRequest = new FilterRequest();
    filterRequest.filter = filter!;
    const endpoint = 'Announcement/searchNewsFeedsPaged'
    const qryStr = `?sortColumn=${sortColumn}&sortDir=${sortDir}&page=${page?.toString()}&pageSize=${pageSize?.toString()}`;
    return this.repository.post(environment.apiBaseURL, endpoint + qryStr, filterRequest);
  }

  public getAllNewsfeedbyId(id: number): Observable<Newsfeed> {
    return new Observable((observer) => {
      this.repository.get(environment.apiBaseURL, `Announcement/GetNewsFeedsById/${id}`)
        .subscribe(data => {
          observer.next(data);
          observer.complete();
        }, (error) => {
          observer.error(error);
        });
    });
  }

  postNewsfeed(objNewsfeed: Newsfeed): Observable<Newsfeed[]>
  {
      let headers = new HttpHeaders()
        .set("Content-Type","application/json");
      console.log(headers);
      return this.repository.post(environment.apiBaseURL, "Announcement/CreateNewsFeeds", JSON.stringify(objNewsfeed), headers);
  }

  putNewsfeed(objNewsfeed: Newsfeed): Observable<Newsfeed[]>
  {
      return this.repository.put(environment.apiBaseURL, "Announcement/EditNewsFeeds", JSON.stringify(objNewsfeed));
  }

}
