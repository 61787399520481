import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialog } from './confirm-dialog/confirm-dialog.component';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AlertDialog } from './alert-dialog/alert-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmReasonDialog } from './confirm-reason-dialog/confirm-reason-dialog.component';
import { SharedModule } from '../shared.module';
import { FormsModule } from '@angular/forms';
import { SuccessDialog } from './success-dialog/success-dialog.component';
import { UppercaseModule } from '../directives/uppercase.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    UppercaseModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  declarations: [ConfirmationDialog, AlertDialog, SuccessDialog, ConfirmReasonDialog]
})
export class DialogsModule {}
