import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phone"
})
export class PhonePipe implements PipeTransform {

    transform(rawNum: string | undefined) {
      
      if(!!rawNum) {
        var num = rawNum as string;
        num = num.includes("-") || num.includes("(") || num.includes(")") || num.includes(" ") 
                  ? num.replace(/[-() ]/g, "") : num;

        const areaCodeStr = num.slice(0,3);
        const midSectionStr = num.slice(3,6);
        const lastSectionStr = num.slice(6, 10);
        return `(${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`;
      }

    }
}