// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  configName: 'default',
  apiBaseURL: 'https://scacapi-loadtesting.azurewebsites.net/',
  funcBaseUrl: 'https://scacapi-loadtesting.azurewebsites.net/api/',
  carrierBaseURL: 'https://scacapi-loadtesting.azurewebsites.net/carrierprofile', 
  requestorContactBaseURL: 'https://scacapi-loadtesting.azurewebsites.net/requestorContact',
  applicationBaseURL: 'https://scacapi-loadtesting.azurewebsites.net/application',
  accountBaseURL: 'https://scacapi-loadtesting.azurewebsites.net/requestor',
  emailBaseUrl: 'https://scacapi-loadtesting.azurewebsites.net/emailer',
  profileBaseUrl: 'https://scacapi-loadtesting.azurewebsites.net/carrierprofile',
  dataProductsBaseUrl: 'https://scacapi-loadtesting.azurewebsites.net/products',
  subscriptionBaseUrl: 'https://scacapi-loadtesting.azurewebsites.net/dataproducts',
  financeBaseUrl: 'https://scacapi-loadtesting.azurewebsites.net/finance',
  notesBaseUrl: 'https://scacapi-loadtesting.azurewebsites.net/notes',
  reservationsBaseUrl: 'https://scacapi-loadtesting.azurewebsites.net/reservations',
  appPageTitle:'NMFTA - National Motor Traffic Association, Inc.',
  oktaIssuer: 'https://partners.nmfta.org/oauth2/default',
  oktaClientId: '0oa40nimjt8N0uwIP697',
  googleAnalyicsTrackId: 'G-B762JL355R',
  appTitle: 'LoadTest - SCAC® Online'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
