import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Newsfeed } from 'app/shared/models/newsfeed.model';
import { NewsfeedService } from 'app/shared/services/newsfeed/newsfeed.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-newsfeed',
  templateUrl: './newsfeed.component.html',
  styleUrls: ['./newsfeed.component.scss']
})
export class NewsfeedComponent implements OnInit {
  @ViewChild('newsfeedmodal', { static: true }) modalcc?: TemplateRef<any>;
  bannerNewsFeeds: Newsfeed[];
  selectedNewsFeed?: Newsfeed;
  constructor(
    private modalService: BsModalService,
    public modalRef: BsModalRef,
    private newsfeedService: NewsfeedService,
  ) { }

  ngOnInit(): void {

    this.newsfeedService.updateNewsfeedBanner$.subscribe((update : boolean) => {
    if (update) {
      this.newsfeedService.getBannerNewsfeeds().subscribe(bannerNewsfeedResponse => {
        this.bannerNewsFeeds = bannerNewsfeedResponse;
      }, error => {
        console.log(error);
      });
    }
  })

    this.newsfeedService.getBannerNewsfeeds().subscribe(bannerNewsfeedResponse => {
      this.bannerNewsFeeds = bannerNewsfeedResponse;
    }, error => {
      console.log(error);
    });
  }

  openModal(template?: TemplateRef<any>, objNewsfeed?: Newsfeed) {
    if (template) {
      this.selectedNewsFeed = objNewsfeed;
      this.modalRef = this.modalService.show(template,
        {
          class: 'modal-lg modal-dialog-centered',
          ignoreBackdropClick: true,
          backdrop: 'static',
          keyboard: false
        });
    }
  }

}
