//Angular inputs
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-scac-loading-spinner',
  templateUrl: './scac-loading-spinner.component.html',
  styleUrls: ['./scac-loading-spinner.component.scss']
})

export class ScacLoadingSpinnerComponent {
  //input/output properties
  @Input() loading: boolean | null = false;
  @Input() orderProcessing?: boolean = false;
}