<div class="centered d-flex align-items-center" *ngIf="loading && orderProcessing">
    <div class="load-box w-100">
        <img src="/assets/scac-logo.svg" class="scac-logo d-block m-auto mb-3">
        <div class="order-processing">
            <h1>Order Processing</h1>
            <h4>Please do not click the Back button or refresh the page.</h4>
        </div>
        <mat-progress-spinner class="m-auto" mode="indeterminate" [diameter]="120">
        </mat-progress-spinner>
    </div>
</div>

<div class="centered d-flex align-items-center" *ngIf="loading && !orderProcessing">
    <div class="load-box w-100">
        <img src="/assets/scac-logo.svg" class="scac-logo d-block m-auto mb-3">
        <div class="loading">
            <h1>Loading...</h1>
        </div>
        <mat-progress-spinner class="m-auto" mode="indeterminate" [diameter]="120">
        </mat-progress-spinner>
    </div>
</div>