//Angular inputs
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//Mat inputs
import { MatIconModule } from '@angular/material/icon';

//Custom component inputs
import { ScrollComponent } from './components/scroll/scroll.component';
import { StepsComponent } from './components/steps/steps.component';
import { PhonePipe } from './pipes/phone.pipe';
import { InputWrapperComponent } from './components/molecules/input-wrapper/input-wrapper.component';
import { LazyTranslateModule } from './lazy-translate.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ErrorMessages } from './services/error-messages/error-messages.service';

@NgModule({
  imports: [
    //Angular inputs
    CommonModule,

    // Custom inputs
    LazyTranslateModule,
    
    //Mat inputs
    MatIconModule,
    MatTooltipModule
  ],
  exports: [
    ScrollComponent, 
    PhonePipe, 
    InputWrapperComponent, 
    StepsComponent
  ],
  declarations: [
    ScrollComponent, 
    PhonePipe, 
    StepsComponent,
    InputWrapperComponent
  ],
  providers: [
    ErrorMessages
  ]
})
export class SharedModule {}
